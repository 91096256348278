.billing-address {
    margin-right: 1rem;
}

.billing .card-container {
    width: 100%;
}

.billing td,
.billing th {
    font-size: 0.8rem;
}

.billing tr td:nth-child(1) {
    width: 40%;
}

.billing td:nth-child(2),
.billing td:nth-child(4) {
    width: 15%;
}

.billing td:nth-child(3) {
    width: 10%;
}

.heading-wrap th {
    padding: 8px;
}

.heading-wrap td,
.heading-wrap th {
    background-color: var(--bs-secondary-bg) !important;
}

input.error {
    border-color: var(--color1);
}

.readonly {
    filter: blur(2px);
    cursor: not-allowed;
    pointer-events: none;
}

.service-modal .modal-content {
    background-color: var(--gray);
}

.service-modal .modal-content .modal-body {
    height: 70vh;
    overflow-y: auto;
}

.service-modal .modal-content .service-btn {
    background-color: #FFF;
    font-size: 14px;
}

.service-modal .modal-content .service-btn.active {
    background-color: var(--color1);
    color: #FFF;
}

.page.billing .disabled {
    filter: blur(3px);
}